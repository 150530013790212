export class WebSocketSingleton {
  private static instance: any = null;
  public webSocket: WebSocket;

  static getInstance() {
    if (!WebSocketSingleton.instance)
      WebSocketSingleton.instance = new WebSocketSingleton();
    return WebSocketSingleton.instance;
  }

  constructor() {
    this.webSocket = new WebSocket("wss://faste.link/api");
  }
}
