import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import QRCode from 'qrcode.react'
import ClipLoader from "react-spinners/ClipLoader";
import { WebSocketSingleton } from './WebSocketSingleton';
import Linkify from 'react-linkify';


console.log('version: 1.3');

function loadKeepAlive(timer: React.MutableRefObject<NodeJS.Timer>, uniqueIdFromBackend: string) {
  console.log("loadKeepAlive(..)");

  clearTimeout(timer.current);
  timer.current = setInterval(() => {
    console.log("### TIMEOUT ###");
    WebSocketSingleton.getInstance().webSocket.send(JSON.stringify({
      matchId: uniqueIdFromBackend,
      status: "keep"
    }))
  }, 9 * 60 * 1000)
}

function App() {
  const [uniqueIdFromBackend, setUniqueId] = useState(undefined);
  const [dataFromApp, setDataFromApp] = useState<string | undefined>(undefined);
  const [needToRefreshPage, setNeedToRefreshPage] = useState(false);
  const timer = useRef<NodeJS.Timer>(setTimeout(() => { }, 0));

  function clearConnection() {
    //clearTimeout(timer.current);
    setUniqueId(undefined)
    setNeedToRefreshPage(true);
  }

  console.log("=== App ===")
  useEffect(() => {
    console.log("=== App: useEffect ===")

    WebSocketSingleton.getInstance().webSocket.onopen = (e: any) => {
      console.log('--- opened --->', e)
    }

    WebSocketSingleton.getInstance().webSocket.onerror = (e: any) => {
      console.error('--- error --->', e)
      clearConnection();
    }

    WebSocketSingleton.getInstance().webSocket.onclose = (e: any) => {
      console.log('--- close --->', e)
      clearConnection();
    }

    WebSocketSingleton.getInstance().webSocket.onmessage = (e: any) => {
      console.log(`--- received --->`, e)
      //loadKeepAlive(timer, uniqueIdFromBackend)

      const received = JSON.parse(e.data)

      console.log("Data:", received)

      if (received.matchId === undefined) {
        setNeedToRefreshPage(true);

        if (received.message === "Internal server error")
          return
        return
      }

      setUniqueId(received.matchId)

      if (received.dataFromApp !== undefined) {
        console.log('data from app:', received.dataFromApp);

        setDataFromApp(received.dataFromApp)
      }
    }

    return () => { console.log("=== App: useEffect returned ===") }
  })

  function onChange(e: any) {
    setDataFromApp(e.target.value)
    var result = WebSocketSingleton.getInstance().webSocket.send(JSON.stringify({
      matchId: uniqueIdFromBackend,
      content: e.target.value
    }))

    console.log('Sent to cloud. Result:', result);
  }

  return (
    <div className="App">
      <header className="App-header">
        {needToRefreshPage
          ?
          <h1>Please refresh the page.</h1>
          :
          <>
            {uniqueIdFromBackend === undefined
              ?
              <ClipLoader color="black" size={100}></ClipLoader>
              :
              <>
                {dataFromApp === undefined
                  ?
                  <>
                    <QRCode value={`https://faste.link/match/${uniqueIdFromBackend}`} size={300} />
                    <h1>Scan me</h1>
                  </>
                  :
                  <>
                    <textarea
                      value={dataFromApp}
                      onChange={onChange}
                      rows={5}
                      cols={5}
                    />
                    <Linkify>{dataFromApp}</Linkify>
                  </>
                }
              </>
            }
          </>
        }
      </header>
    </div>
  );
}

export default App;
